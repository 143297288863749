body
  background-color: #dff77811
  
  .container
    display: grid
    grid-template-columns: auto auto auto
    grid-template-rows: auto auto auto 

  .header
    grid-column-start: span 3
    display: flex
    align-items: center
    justify-content: center
    
  .content
    grid-row-start: 2
    grid-row-end: 4
    grid-column-start: span 3
    display: flex
    flex-direction: column
    align-items: center
    justify-content: flex-start

  .post
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
  
  .post-text
    color: white
    background-color: black
  
  .post-content
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    .post-video
      display: flex
      flex-direction: column
      align-items: center
      justify-content: center
      iframe
        margin-top: 10px
        margin-bottom: 10px



  